.player-form {
  position: absolute;
  top: 50%;
  left: 50%;
}
.player-form label span {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

/*# sourceMappingURL=PlayerForm.css.map */
