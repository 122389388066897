.settings header {
  margin-bottom: 20px;
}
.settings header .title, .settings header .views {
  display: flex;
  align-items: center;
  gap: 20px;
}
.settings .gameplay {
  position: relative;
}
.settings .flame-info {
  position: absolute;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
}
.settings .info {
  position: absolute;
  top: 0;
  right: 0;
}
.settings .timer {
  display: flex;
  gap: 20px;
}
.settings .views {
  position: absolute;
  left: 0;
  bottom: 0;
}
.settings .views button {
  margin-right: 20px;
}
.settings .device-info {
  position: relative;
}
.settings .device-info .form-component {
  min-width: 516px;
  padding-top: 40px;
}
.settings .device-info h2 {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.settings .device-info p {
  margin-bottom: 30px;
  text-align: center;
  font-size: 20px;
}
.settings .device-info p:last-of-type {
  margin-bottom: 40px;
  font-size: 14px;
}
.settings .device-info button {
  width: 100%;
  max-width: 336px;
  margin: auto;
}
.settings main .setup-info {
  align-self: center;
  text-align: center;
}
.settings main p {
  margin: 40px 0;
}
.settings main .time p {
  margin: 0;
}

/*# sourceMappingURL=Simulation.css.map */
