@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

:root {
    --h1-size: 36px;
    --h2-size: 20px;
    --h3-size: 14px;
    --p1-size: 20px;
    --p-size: 14px;

    --p-weight: 400;
    --h-weight: 700;


    --purple-gradient: linear-gradient(180deg, rgba(76, 73, 118, 0.65) 0%, #212046 100%);
    --button-gradient: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);
    --glowing-shadow: 0 0 4px #40DDFF;

    --item-color: #6F6C99;
    --item-selected-color: #40DDFF;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #13B4E4;
    border-radius: 10px;
}

body {
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: radial-gradient(123.22% 129.67% at 100.89% -5.6%, #4A449F 0%, #171534 100%);
    background: -webkit-radial-gradient(123.22% 129.67% at 100.89% -5.6%, #4A449F 0%, #171534 100%);
    background: -o-radial-gradient(123.22% 129.67% at 100.89% -5.6%, #4A449F 0%, #171534 100%);
    background: -ms-radial-gradient(123.22% 129.67% at 100.89% -5.6%, #4A449F 0%, #171534 100%);
}

body, input, select, button {
    font-family: Ubuntu, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button img {
    pointer-events: none;
}