.minmax-form {
  position: absolute;
  top: 50%;
  width: 450px;
  display: flex;
  flex-direction: column;
  padding: 60px 50px 40px;
  margin-top: 0;
}
.minmax-form .minmax-slider-container {
  margin-bottom: 20px;
}
.minmax-form .minmax-slider-container:first-of-type {
  margin-bottom: 40px;
}
.minmax-form input {
  width: 140px;
  height: 50px;
  margin: 0 !important;
  position: relative;
}
.minmax-form input::-webkit-inner-spin-button, .minmax-form input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

/*# sourceMappingURL=MinMaxForm.css.map */
