.shadow main:not(.content) {
  min-height: 100vh;
}
.shadow main:not(.content) .info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.shadow main:not(.content) .table {
  margin-bottom: 100px;
}

/*# sourceMappingURL=SessionForm.css.map */
