.loader {
  width: 200px;
  margin: 0 auto;
  animation: load 1.5s linear infinite;
  transform-origin: center;
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/*# sourceMappingURL=Loader.css.map */
