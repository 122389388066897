.password-input {
  position: relative;
  width: 100%;
}
.password-input input {
  width: 100%;
}
.password-input img {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

/*# sourceMappingURL=PasswordInput.css.map */
