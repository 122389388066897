.table {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 300px);
  max-width: 100%;
  border-radius: 10px;
  overflow-y: auto;
}
@media screen and (max-height: 800px) {
  .table {
    max-height: calc(100vh - 100px);
  }
}
.table__container {
  min-width: 100%;
  width: fit-content;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.table__line {
  display: grid;
  gap: 20px;
  align-items: center;
  padding: 20px;
  color: white;
}
.table__line:nth-child(odd) {
  background: rgba(0, 0, 0, 0.3);
}
.table__line:nth-child(even) {
  background: rgba(204, 204, 204, 0.1);
}
.table__line.is-header {
  background: transparent;
}
.table__line p, .table__line h2, .table__line h3 {
  display: flex;
  align-items: center;
  text-transform: none;
}
.table__line p img, .table__line h2 img, .table__line h3 img {
  margin-right: 10px;
}

/*# sourceMappingURL=TableLine.css.map */
