.range-slider p {
  margin-bottom: 20px;
}
.range-slider .slide-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
.range-slider .slide-container .slider {
  width: 70%;
}

/*# sourceMappingURL=RangeSlider.css.map */
