.statistics main {
  max-height: 100vh;
}
.statistics .content {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statistics .filtering {
  display: flex;
  gap: 20px;
}
.statistics header {
  margin-bottom: 50px;
}
.statistics .table__line div:nth-child(n+4) h2 {
  justify-content: flex-end;
}
.statistics .table {
  max-height: 100%;
  margin-bottom: 50px;
}
.statistics .checkboxes {
  display: flex;
  gap: 20px;
}
@media (max-width: 1500px) {
  .statistics .checkboxes {
    flex-wrap: wrap;
  }
}
.statistics .table__line:first-of-type {
  border-top-right-radius: 10px;
}
.statistics .table__line:last-of-type {
  border-bottom-right-radius: 10px;
}
.statistics .table__line div:nth-child(4) {
  padding-right: 40px;
}

/*# sourceMappingURL=Statistics.css.map */
