.graph-info, .game-info {
  max-width: fit-content;
  margin-left: auto;
  margin-bottom: 20px;
  background: var(--purple-gradient);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  backdrop-filter: blur(26px);
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.graph-info {
  max-width: 180px;
  min-height: 100px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 40px 1fr;
  grid-row-gap: 5px;
  padding: 12px;
}
.graph-info .graph {
  grid-column: span 2;
}

.game-info {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
}

/*# sourceMappingURL=GraphInfo.css.map */
