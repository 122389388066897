.flame header {
  margin-bottom: 100px;
}
.flame .content {
  height: calc(80% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flame .content .flame-settings {
  height: 100%;
  width: 60%;
}
.flame .content .flame-settings .range-slider {
  margin-bottom: 50px;
}

.sessionSettings {
  width: 100%;
  padding: 0 150px;
}
@media screen and (max-width: 1250px) {
  .sessionSettings {
    padding: 0 50px;
  }
}
.sessionSettings main {
  display: flex;
  padding: 80px 0;
}
.sessionSettings main .flame-settings {
  width: 60%;
}
.sessionSettings main .oxygen {
  margin: 0 auto;
}
.sessionSettings main .oxygen label {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sessionSettings .controls {
  align-items: stretch;
}
.sessionSettings .controls button {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-image: var(--purple-gradient);
  text-align: left;
}
.sessionSettings .controls button img {
  margin-right: 20px;
}
.sessionSettings .save button:first-of-type {
  margin-right: 20px;
}

.shadow .sessionSettings {
  position: absolute;
  top: 50%;
  left: 50%;
}

/*# sourceMappingURL=SessionSettings.css.map */
