@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap);
:root {
    --h1-size: 36px;
    --h2-size: 20px;
    --h3-size: 14px;
    --p1-size: 20px;
    --p-size: 14px;

    --p-weight: 400;
    --h-weight: 700;


    --purple-gradient: linear-gradient(180deg, rgba(76, 73, 118, 0.65) 0%, #212046 100%);
    --button-gradient: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);
    --glowing-shadow: 0 0 4px #40DDFF;

    --item-color: #6F6C99;
    --item-selected-color: #40DDFF;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #13B4E4;
    border-radius: 10px;
}

body {
    margin: 0;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: radial-gradient(123.22% 129.67% at 100.89% -5.6%, #4A449F 0%, #171534 100%);
    background: -ms-radial-gradient(123.22% 129.67% at 100.89% -5.6%, #4A449F 0%, #171534 100%);
}

body, input, select, button {
    font-family: Ubuntu, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button img {
    pointer-events: none;
}
* {
  text-transform: uppercase;
  box-sizing: border-box;
}

input, textarea, select {
  text-transform: none;
}

#root, .page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex: 1 1;
}

.page main {
  position: relative;
  width: calc(100vw - 390px);
  padding: 50px;
}

.page main p, .page main h1, .page main h2, .page main h3 {
  color: white;
}

h1, h2, h3, p {
  margin: 0;
}

h1, h2, h3 {
  font-weight: var(--h-weight);
}

h1 {
  font-size: var(--h1-size);
}

h2 {
  font-size: var(--h2-size);
}

h3 {
  font-size: var(--h3-size);
}

p {
  font-size: var(--p-size);
  font-weight: var(--p-weight);
}

p.p1 {
  font-size: var(--p1-size);
}

h1 a, h2 a, h3 a {
  color: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

.form-input {
  width: 100%;
  padding: 16px 8px;
  color: white;
  font-size: var(--p1-size);
  font-weight: var(--p-weight);
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
}

.form-input:not(.password):focus, .open {
  color: black;
  background: #FFFFFF;
  border-color: var(--item-selected-color);
  box-shadow: 0 0 4px var(--item-selected-color);
  outline: none;
}

.form-input:focus::-webkit-input-placeholder {
  color: black;
}

.form-input:focus::placeholder {
  color: black;
}

.form-input:disabled, .form-input.disabled {
  color: #D9D9D9;
  border-color: #D9D9D9;
}

.form-input::-webkit-input-placeholder {
  color: white;
}

.form-input::placeholder {
  color: white;
}

.column {
  display: flex;
  flex-direction: column;
}

.icon:hover {
  cursor: pointer;
}

.dn {
  display: none;
}

header h1, header h2 {
  color: white;
}

header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .controls {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2em);
  -webkit-backdrop-filter: blur(2em);
  z-index: 100;
}

.shadow h2 {
  text-align: center;
}

.form-component {
  min-width: 336px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #40DDFF;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.1);
}

.view-buttons {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.gameplay {
  height: calc(100% - 80px);
  display: grid;
  align-items: flex-end;
  justify-content: center;
}

.dot {
  min-width: 16px;
  min-height: 16px;
  background-color: #66EEA2;
  border-radius: 50%;
}

.dot.red {
  background-color: red;
}

.connection-ip {
  display: grid;
  grid-template-columns: 1fr 16px;
}

.output {
  min-width: 60px;
  min-height: 60px;
  padding: 0 !important;
  display: grid;
  place-items: center;
  cursor: auto !important;
}

.output:hover {
  background: none !important;
  box-shadow: none !important;
  border: 2px solid white !important;
}

.back-button {
  min-width: 60px;
  min-height: 60px;
  display: grid;
  place-items: center;
}

.filter {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.filter input {
  width: 330px;
}

.page .MuiSlider-thumbColorPrimary {
  background: #13B4E4;
  box-shadow: none;
}

.page .MuiSlider-thumbColorPrimary:hover {
  box-shadow: var(--glowing-shadow);
}

.page .MuiSlider-markLabel {
  color: white;
  font-size: var(--p-size);
}

.page .MuiSlider-track {
  background: var(--item-selected-color);
}

.page .MuiSlider-rail {
  background: rgba(255, 255, 255, 0.2);
}

.page .MuiSlider-mark {
  height: 30px;
  background: white;
}

.page .MuiSlider-mark[data-index="0"], .page .MuiSlider-mark[data-index="2"] {
  display: none;
}

.page .MuiSlider-valueLabel {
  padding-bottom: 0;
  background: transparent;
  -webkit-transform: translateY(-80%);
          transform: translateY(-80%);
  font-size: var(--p-size);
  font-family: 'Ubuntu', 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
}

.temp {
  position: absolute;
  top: -100px;
}

.flex {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.reverse {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  grid-gap: 10px;
  gap: 10px;
}

.jcc {
  justify-content: center;
}

.version {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}

.version span {
  text-transform: lowercase;
}

.auth {
  min-height: 100%;
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-rows: 380px 1fr;
  padding-top: 100px;
}
.auth [class^=form-] {
  min-width: 250px;
  margin-bottom: 20px;
}

/*# sourceMappingURL=Auth.css.map */

.form-button {
  padding: 16px 20px;
  color: white;
  text-align: center;
  font-size: var(--h2-size);
  font-weight: var(--h-weight);
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  background: transparent;
}
.form-button img {
  display: block;
}
.form-button:hover:not(.hidden-ui):not(.disabled), .form-button.selected {
  padding: 18px 22px;
  border: none;
  background: var(--button-gradient);
  box-shadow: 0 0 4px var(--item-selected-color);
  cursor: pointer;
}
.form-button.selected {
  box-shadow: none;
}
.form-button:active:not(.hidden-ui):not(.disabled) {
  outline: none;
  box-shadow: none;
}
.form-button:disabled, .form-button.disabled {
  padding: 16px 20px;
  color: #5F5F5F;
  background: transparent;
  border: 2px solid #5F5F5F;
  box-shadow: none;
  cursor: auto;
}
.form-button.hidden-ui {
  padding: 0;
  border: none;
  background: transparent;
}
.form-button.only-icon {
  padding: 14px;
}
.form-button.only-icon:hover:not(.hidden-ui):not(.disabled), .form-button.only-icon.selected {
  padding: 16px;
}
.form-button.icon-13 {
  padding: 13px;
}
.form-button.icon-13:hover:not(.hidden-ui):not(.disabled), .form-button.icon-13.selected {
  padding: 15px;
}
.form-button.big-button {
  width: 336px;
}

/*# sourceMappingURL=Button.css.map */

.error .form-component {
  border-color: #FE242E;
}

.lowercase * {
  text-transform: none;
}

/*# sourceMappingURL=Alert.css.map */

.sidebar {
  min-width: 390px;
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  background: linear-gradient(180deg, rgba(76, 73, 118, 0.65) 0%, #212046 100%);
  transition: all 0.5s ease-in-out;
}
.sidebar .logo {
  max-width: 200px;
  margin: 0 auto 50px;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.sidebar .hidden {
  display: none;
  width: 60px;
  height: 60px;
  margin-bottom: 50px;
}
.sidebar.is-small {
  position: relative;
  min-width: 100px;
  width: 100px;
  padding: 50px 35px;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.sidebar.is-small .logo {
  position: absolute;
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
}
.sidebar.is-small .hidden {
  display: block;
}

.sidebar.is-small .sidebar__menu__item img {
  margin: 0 auto;
}

.sidebar.is-small .submenu {
  padding: 0;
}

.sidebar.is-small + main {
  width: calc(100vw - 100px);
}

/*# sourceMappingURL=Sidebar.css.map */

.sidebar__menu__item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 50px;
  color: var(--item-color);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.sidebar__menu__item img {
  margin-right: 10px;
  will-change: contents;
  transition: all 0.5s ease-in-out;
}
.sidebar__menu__item.active, .sidebar__menu__item:hover {
  color: var(--item-selected-color);
}
.sidebar__menu__item h2, .sidebar__menu__item h3 {
  position: absolute;
  left: 40px;
  margin: 0;
  transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  will-change: transform;
}
.sidebar__menu__item h2.hide, .sidebar__menu__item h3.hide {
  transition: opacity 0s ease-in-out, -webkit-transform 0s ease-in-out;
  transition: opacity 0s ease-in-out, transform 0s ease-in-out;
  transition: opacity 0s ease-in-out, transform 0s ease-in-out, -webkit-transform 0s ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
}
.sidebar__menu__item h2 {
  top: 4px;
}
.sidebar__menu__item h3 {
  top: 8px;
}
.sidebar__menu__item .submenu {
  width: 100%;
  margin-top: 25px;
  padding-left: 40px;
  transition: all 0.5s ease-in-out;
}
.sidebar__menu__item .submenu .sidebar__menu__item {
  margin-bottom: 25px;
}
.sidebar__menu__item .submenu .sidebar__menu__item:last-of-type {
  margin-bottom: 0;
}

/*# sourceMappingURL=SidebarMenuItem.css.map */

.nameblock {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: auto 0 36px;
}
.nameblock .initials {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C4C4C4;
  border-radius: 50%;
  border: 4px solid #FFCC33;
  font-size: 20px;
}
.nameblock .fullname {
  display: none;
  font-size: 18px;
  color: white;
}
.nameblock.full .initials {
  margin-right: 10px;
}
.nameblock.full .fullname {
  display: block;
}

/*# sourceMappingURL=NameBlock.css.map */

.admin-panel main {
  padding: 50px 50px 110px;
}

header {
  margin-bottom: 44px;
}

/*# sourceMappingURL=AdminPanel.css.map */

.selectContainer {
  position: relative;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 8px;
  border-radius: 10px;
  transition: border-radius 0.5s ease-in-out;
}
.select .control {
  padding: 18px;
  border-left: 2px solid white;
}
.select .control img {
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.select:hover, .select.is-active {
  background: white;
  color: black;
  border-color: var(--item-selected-color);
  box-shadow: var(--glowing-shadow);
  overflow: hidden;
  cursor: pointer;
}
.select:hover .control, .select.is-active .control {
  border-color: var(--item-selected-color);
  background: var(--button-gradient);
}
.select.is-active {
  border-bottom-left-radius: 0;
}
.select.is-active .control img {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.options {
  position: absolute;
  width: 80%;
  color: black;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  z-index: 2;
}
.options__option {
  padding: 18px 8px;
  border: 2px solid var(--item-selected-color);
  border-top: none;
  background: white;
  font-size: var(--p1-size);
  cursor: pointer;
}
.options__option:hover {
  background: var(--item-selected-color);
  color: white;
}
.options__option:last-of-type {
  border-radius: 0 0 10px 10px;
}
.options.hidden {
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.selectContainer.small .control {
  padding: 16px;
}
.selectContainer.small .selected-option {
  padding: 16px 16px 16px 8px;
}
.selectContainer.small .options {
  bottom: 59px;
  width: 52%;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
.selectContainer.small .options__option {
  border-top: 2px solid var(--item-selected-color);
  border-bottom: 0;
  text-align: center;
}
.selectContainer.small .options__option:first-of-type {
  border-radius: 10px 10px 0 0;
}
.selectContainer.small .options__option:last-of-type {
  border-radius: 0;
}
.selectContainer.small .select.is-active {
  border-top-left-radius: unset;
  border-bottom-left-radius: 10px;
}

/*# sourceMappingURL=Select.css.map */

.password-input {
  position: relative;
  width: 100%;
}
.password-input input {
  width: 100%;
}
.password-input img {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

/*# sourceMappingURL=PasswordInput.css.map */

.player-form {
  position: absolute;
  top: 50%;
  left: 50%;
}
.player-form label span {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

/*# sourceMappingURL=PlayerForm.css.map */

.table {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 300px);
  max-width: 100%;
  border-radius: 10px;
  overflow-y: auto;
}
@media screen and (max-height: 800px) {
  .table {
    max-height: calc(100vh - 100px);
  }
}
.table__container {
  min-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.table__line {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  padding: 20px;
  color: white;
}
.table__line:nth-child(odd) {
  background: rgba(0, 0, 0, 0.3);
}
.table__line:nth-child(even) {
  background: rgba(204, 204, 204, 0.1);
}
.table__line.is-header {
  background: transparent;
}
.table__line p, .table__line h2, .table__line h3 {
  display: flex;
  align-items: center;
  text-transform: none;
}
.table__line p img, .table__line h2 img, .table__line h3 img {
  margin-right: 10px;
}

/*# sourceMappingURL=TableLine.css.map */

.pagination {
  position: absolute;
  left: 50px;
  bottom: 50px;
  display: flex;
  align-items: flex-start;
}

.cdp {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 20px;
  font-size: 0;
  -webkit-user-select: none;
          user-select: none;
}
.cdp_i {
  display: none;
  min-width: 60px;
  padding: 19px 12px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  transition: color 250ms;
}
.cdp_i:last-child, .cdp_i:nth-child(2), .cdp_i:nth-last-child(2) {
  display: inline-block;
}
.cdp_i.clickable {
  border: 2px solid white;
  border-radius: 10px;
  font-size: unset;
  padding: 12px;
}
.cdp_i.clickable img {
  width: 30px;
  height: 30px;
}
.cdp_i:hover {
  color: var(--item-selected-color);
  cursor: pointer;
}
.cdp:not([data-actpage="1"]) .cdp_i:nth-child(1) {
  display: inline-block;
}

.cdp[data-actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(0):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(2), .cdp[data-actpage="1"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(2) + .cdp_i:last-child, .cdp[data-actpage="1"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(3):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(3), .cdp[data-actpage="2"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(3) + .cdp_i:last-child, .cdp[data-actpage="2"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(4), .cdp[data-actpage="3"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(4) + .cdp_i:last-child, .cdp[data-actpage="3"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(5), .cdp[data-actpage="4"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(5) + .cdp_i:last-child, .cdp[data-actpage="4"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(6), .cdp[data-actpage="5"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(6) + .cdp_i:last-child, .cdp[data-actpage="5"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(7), .cdp[data-actpage="6"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(7) + .cdp_i:last-child, .cdp[data-actpage="6"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(8), .cdp[data-actpage="7"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(8) + .cdp_i:last-child, .cdp[data-actpage="7"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(9), .cdp[data-actpage="8"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(9) + .cdp_i:last-child, .cdp[data-actpage="8"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(10), .cdp[data-actpage="9"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(10) + .cdp_i:last-child, .cdp[data-actpage="9"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(11), .cdp[data-actpage="10"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(11) + .cdp_i:last-child, .cdp[data-actpage="10"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(12), .cdp[data-actpage="11"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(12) + .cdp_i:last-child, .cdp[data-actpage="11"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(13), .cdp[data-actpage="12"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(13) + .cdp_i:last-child, .cdp[data-actpage="12"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(14), .cdp[data-actpage="13"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(14) + .cdp_i:last-child, .cdp[data-actpage="13"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(15), .cdp[data-actpage="14"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(15) + .cdp_i:last-child, .cdp[data-actpage="14"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(16), .cdp[data-actpage="15"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(16) + .cdp_i:last-child, .cdp[data-actpage="15"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(17), .cdp[data-actpage="16"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(17) + .cdp_i:last-child, .cdp[data-actpage="16"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(18), .cdp[data-actpage="17"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(18) + .cdp_i:last-child, .cdp[data-actpage="17"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(19), .cdp[data-actpage="18"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(19) + .cdp_i:last-child, .cdp[data-actpage="18"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(20), .cdp[data-actpage="19"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(20) + .cdp_i:last-child, .cdp[data-actpage="19"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(21), .cdp[data-actpage="20"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(21) + .cdp_i:last-child, .cdp[data-actpage="20"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(22), .cdp[data-actpage="21"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(22) + .cdp_i:last-child, .cdp[data-actpage="21"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(23), .cdp[data-actpage="22"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(23) + .cdp_i:last-child, .cdp[data-actpage="22"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(24), .cdp[data-actpage="23"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(24) + .cdp_i:last-child, .cdp[data-actpage="23"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(25), .cdp[data-actpage="24"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(25) + .cdp_i:last-child, .cdp[data-actpage="24"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(26), .cdp[data-actpage="25"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(26) + .cdp_i:last-child, .cdp[data-actpage="25"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(27), .cdp[data-actpage="26"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(27) + .cdp_i:last-child, .cdp[data-actpage="26"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(28), .cdp[data-actpage="27"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(28) + .cdp_i:last-child, .cdp[data-actpage="27"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(29), .cdp[data-actpage="28"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(29) + .cdp_i:last-child, .cdp[data-actpage="28"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(30), .cdp[data-actpage="29"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(30) + .cdp_i:last-child, .cdp[data-actpage="29"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(31), .cdp[data-actpage="30"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(31) + .cdp_i:last-child, .cdp[data-actpage="30"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(32), .cdp[data-actpage="31"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(32) + .cdp_i:last-child, .cdp[data-actpage="31"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(33), .cdp[data-actpage="32"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(33) + .cdp_i:last-child, .cdp[data-actpage="32"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(34), .cdp[data-actpage="33"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(34) + .cdp_i:last-child, .cdp[data-actpage="33"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(35), .cdp[data-actpage="34"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(35) + .cdp_i:last-child, .cdp[data-actpage="34"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(36), .cdp[data-actpage="35"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(36) + .cdp_i:last-child, .cdp[data-actpage="35"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(37), .cdp[data-actpage="36"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(37) + .cdp_i:last-child, .cdp[data-actpage="36"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(38), .cdp[data-actpage="37"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(38) + .cdp_i:last-child, .cdp[data-actpage="37"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(39), .cdp[data-actpage="38"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(39) + .cdp_i:last-child, .cdp[data-actpage="38"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(40), .cdp[data-actpage="39"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(40) + .cdp_i:last-child, .cdp[data-actpage="39"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(41), .cdp[data-actpage="40"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(41) + .cdp_i:last-child, .cdp[data-actpage="40"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(42), .cdp[data-actpage="41"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(42) + .cdp_i:last-child, .cdp[data-actpage="41"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(43), .cdp[data-actpage="42"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(43) + .cdp_i:last-child, .cdp[data-actpage="42"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(44), .cdp[data-actpage="43"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(44) + .cdp_i:last-child, .cdp[data-actpage="43"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(45), .cdp[data-actpage="44"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(45) + .cdp_i:last-child, .cdp[data-actpage="44"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(46), .cdp[data-actpage="45"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(46) + .cdp_i:last-child, .cdp[data-actpage="45"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(47), .cdp[data-actpage="46"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(47) + .cdp_i:last-child, .cdp[data-actpage="46"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(48), .cdp[data-actpage="47"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(48) + .cdp_i:last-child, .cdp[data-actpage="47"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(49), .cdp[data-actpage="48"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(49) + .cdp_i:last-child, .cdp[data-actpage="48"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(50), .cdp[data-actpage="49"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(50) + .cdp_i:last-child, .cdp[data-actpage="49"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(51), .cdp[data-actpage="50"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(51) + .cdp_i:last-child, .cdp[data-actpage="50"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(52), .cdp[data-actpage="51"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(52) + .cdp_i:last-child, .cdp[data-actpage="51"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(53), .cdp[data-actpage="52"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(53) + .cdp_i:last-child, .cdp[data-actpage="52"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(54), .cdp[data-actpage="53"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(54) + .cdp_i:last-child, .cdp[data-actpage="53"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(55), .cdp[data-actpage="54"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(55) + .cdp_i:last-child, .cdp[data-actpage="54"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(56), .cdp[data-actpage="55"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(56) + .cdp_i:last-child, .cdp[data-actpage="55"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(57), .cdp[data-actpage="56"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(57) + .cdp_i:last-child, .cdp[data-actpage="56"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(58), .cdp[data-actpage="57"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(58) + .cdp_i:last-child, .cdp[data-actpage="57"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(59), .cdp[data-actpage="58"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(59) + .cdp_i:last-child, .cdp[data-actpage="58"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(60), .cdp[data-actpage="59"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(60) + .cdp_i:last-child, .cdp[data-actpage="59"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(61), .cdp[data-actpage="60"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(61) + .cdp_i:last-child, .cdp[data-actpage="60"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(62), .cdp[data-actpage="61"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(62) + .cdp_i:last-child, .cdp[data-actpage="61"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(63), .cdp[data-actpage="62"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(63) + .cdp_i:last-child, .cdp[data-actpage="62"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(64), .cdp[data-actpage="63"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(64) + .cdp_i:last-child, .cdp[data-actpage="63"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(65), .cdp[data-actpage="64"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(65) + .cdp_i:last-child, .cdp[data-actpage="64"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(66), .cdp[data-actpage="65"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(66) + .cdp_i:last-child, .cdp[data-actpage="65"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(67), .cdp[data-actpage="66"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(67) + .cdp_i:last-child, .cdp[data-actpage="66"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(68), .cdp[data-actpage="67"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(68) + .cdp_i:last-child, .cdp[data-actpage="67"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(69), .cdp[data-actpage="68"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(69) + .cdp_i:last-child, .cdp[data-actpage="68"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(70), .cdp[data-actpage="69"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(70) + .cdp_i:last-child, .cdp[data-actpage="69"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(71), .cdp[data-actpage="70"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(71) + .cdp_i:last-child, .cdp[data-actpage="70"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(72), .cdp[data-actpage="71"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(72) + .cdp_i:last-child, .cdp[data-actpage="71"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(73), .cdp[data-actpage="72"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(73) + .cdp_i:last-child, .cdp[data-actpage="72"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(74), .cdp[data-actpage="73"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(74) + .cdp_i:last-child, .cdp[data-actpage="73"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(75), .cdp[data-actpage="74"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(75) + .cdp_i:last-child, .cdp[data-actpage="74"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(76), .cdp[data-actpage="75"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(76) + .cdp_i:last-child, .cdp[data-actpage="75"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(77), .cdp[data-actpage="76"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(77) + .cdp_i:last-child, .cdp[data-actpage="76"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(78), .cdp[data-actpage="77"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(78) + .cdp_i:last-child, .cdp[data-actpage="77"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(79), .cdp[data-actpage="78"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(79) + .cdp_i:last-child, .cdp[data-actpage="78"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(80), .cdp[data-actpage="79"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(80) + .cdp_i:last-child, .cdp[data-actpage="79"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(80):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(81), .cdp[data-actpage="80"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(81) + .cdp_i:last-child, .cdp[data-actpage="80"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(83):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

/*# sourceMappingURL=Pagination.css.map */

.loader {
  width: 200px;
  margin: 0 auto;
  -webkit-animation: load 1.5s linear infinite;
          animation: load 1.5s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
}

@-webkit-keyframes load {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes load {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

/*# sourceMappingURL=Loader.css.map */

.statistics main {
  max-height: 100vh;
}
.statistics .content {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statistics .filtering {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.statistics header {
  margin-bottom: 50px;
}
.statistics .table__line div:nth-child(n+4) h2 {
  justify-content: flex-end;
}
.statistics .table {
  max-height: 100%;
  margin-bottom: 50px;
}
.statistics .checkboxes {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
@media (max-width: 1500px) {
  .statistics .checkboxes {
    flex-wrap: wrap;
  }
}
.statistics .table__line:first-of-type {
  border-top-right-radius: 10px;
}
.statistics .table__line:last-of-type {
  border-bottom-right-radius: 10px;
}
.statistics .table__line div:nth-child(4) {
  padding-right: 40px;
}

/*# sourceMappingURL=Statistics.css.map */

.checkbox__container {
  width: -webkit-min-content;
  width: min-content;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}
.checkbox__container:hover .checkbox {
  border-color: var(--item-selected-color);
}
.checkbox__container .checkbox {
  min-height: 30px;
  min-width: 30px;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}
.checkbox__container .checkbox:hover {
  border-color: var(--item-selected-color);
}

/*# sourceMappingURL=Checkbox.css.map */

.minmax-slider-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

/*# sourceMappingURL=MinMaxSlider.css.map */

.minmax-form {
  position: absolute;
  top: 50%;
  width: 450px;
  display: flex;
  flex-direction: column;
  padding: 60px 50px 40px;
  margin-top: 0;
}
.minmax-form .minmax-slider-container {
  margin-bottom: 20px;
}
.minmax-form .minmax-slider-container:first-of-type {
  margin-bottom: 40px;
}
.minmax-form input {
  width: 140px;
  height: 50px;
  margin: 0 !important;
  position: relative;
}
.minmax-form input::-webkit-inner-spin-button, .minmax-form input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

/*# sourceMappingURL=MinMaxForm.css.map */

.settings header {
  margin-bottom: 20px;
}
.settings header .title, .settings header .views {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.settings .gameplay {
  position: relative;
}
.settings .flame-info {
  position: absolute;
  top: 40%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.settings .info {
  position: absolute;
  top: 0;
  right: 0;
}
.settings .timer {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.settings .views {
  position: absolute;
  left: 0;
  bottom: 0;
}
.settings .views button {
  margin-right: 20px;
}
.settings .device-info {
  position: relative;
}
.settings .device-info .form-component {
  min-width: 516px;
  padding-top: 40px;
}
.settings .device-info h2 {
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.settings .device-info p {
  margin-bottom: 30px;
  text-align: center;
  font-size: 20px;
}
.settings .device-info p:last-of-type {
  margin-bottom: 40px;
  font-size: 14px;
}
.settings .device-info button {
  width: 100%;
  max-width: 336px;
  margin: auto;
}
.settings main .setup-info {
  align-self: center;
  text-align: center;
}
.settings main p {
  margin: 40px 0;
}
.settings main .time p {
  margin: 0;
}

/*# sourceMappingURL=Simulation.css.map */

.indicator {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.indicator .box {
  margin-right: 20px;
}

.box {
  width: 50px;
  height: 50px;
  border-radius: 3px;
}
.box.flame {
  display: grid;
  place-items: center;
  background: #F92B53;
}
.box.stop {
  background: #FFCC33;
}
.box.water {
  background: #437DF2;
}
.box.player-dot {
  position: relative;
  height: 25px;
  width: 25px;
  margin: 12.5px 32.5px 20px 12.5px;
  border-radius: 50%;
  background: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);
}
.box.player-dot:before, .box.player-dot:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.box.player-dot:after {
  -webkit-transform: translate(-50%, -50%) scale(1.75);
          transform: translate(-50%, -50%) scale(1.75);
  background: rgba(64, 221, 255, 0.75);
}
.box.player-dot:before {
  -webkit-transform: translate(-50%, -50%) scale(2);
          transform: translate(-50%, -50%) scale(2);
  background: rgba(64, 221, 255, 0.5);
  -webkit-animation: blink 2s forwards infinite;
          animation: blink 2s forwards infinite;
}

@-webkit-keyframes blink {
  from {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(2);
            transform: translate(-50%, -50%) scale(2);
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(3);
            transform: translate(-50%, -50%) scale(3);
  }
}

@keyframes blink {
  from {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(2);
            transform: translate(-50%, -50%) scale(2);
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(3);
            transform: translate(-50%, -50%) scale(3);
  }
}

/*# sourceMappingURL=MapIndicators.css.map */

.graph-info, .game-info {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: auto;
  margin-bottom: 20px;
  background: var(--purple-gradient);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.graph-info {
  max-width: 180px;
  min-height: 100px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 40px 1fr;
  grid-row-gap: 5px;
  padding: 12px;
}
.graph-info .graph {
  grid-column: span 2;
}

.game-info {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 15px 20px;
}

/*# sourceMappingURL=GraphInfo.css.map */

.end-screen .table {
  margin-top: 100px;
}
.end-screen h1, .end-screen .form-button {
  display: block;
  margin: 100px auto 0;
  text-align: center;
}
.end-screen .top .table__line {
  background: rgba(204, 204, 204, 0.5);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(26px);
          backdrop-filter: blur(26px);
  border-radius: 10px 10px 0 0;
}
.end-screen .bottom .table__line {
  background: var(--purple-gradient);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 0 0 10px 10px;
}

/*# sourceMappingURL=EndScreen.css.map */

.flame header {
  margin-bottom: 100px;
}
.flame .content {
  height: calc(80% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flame .content .flame-settings {
  height: 100%;
  width: 60%;
}
.flame .content .flame-settings .range-slider {
  margin-bottom: 50px;
}

.sessionSettings {
  width: 100%;
  padding: 0 150px;
}
@media screen and (max-width: 1250px) {
  .sessionSettings {
    padding: 0 50px;
  }
}
.sessionSettings main {
  display: flex;
  padding: 80px 0;
}
.sessionSettings main .flame-settings {
  width: 60%;
}
.sessionSettings main .oxygen {
  margin: 0 auto;
}
.sessionSettings main .oxygen label {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.sessionSettings .controls {
  align-items: stretch;
}
.sessionSettings .controls button {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-image: var(--purple-gradient);
  text-align: left;
}
.sessionSettings .controls button img {
  margin-right: 20px;
}
.sessionSettings .save button:first-of-type {
  margin-right: 20px;
}

.shadow .sessionSettings {
  position: absolute;
  top: 50%;
  left: 50%;
}

/*# sourceMappingURL=SessionSettings.css.map */

.range-slider p {
  margin-bottom: 20px;
}
.range-slider .slide-container {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.range-slider .slide-container .slider {
  width: 70%;
}

/*# sourceMappingURL=RangeSlider.css.map */

.shadow main:not(.content) {
  min-height: 100vh;
}
.shadow main:not(.content) .info {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.shadow main:not(.content) .table {
  margin-bottom: 100px;
}

/*# sourceMappingURL=SessionForm.css.map */

