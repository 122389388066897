.pagination {
  position: absolute;
  left: 50px;
  bottom: 50px;
  display: flex;
  align-items: flex-start;
}

.cdp {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 20px;
  font-size: 0;
  user-select: none;
}
.cdp_i {
  display: none;
  min-width: 60px;
  padding: 19px 12px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  transition: color 250ms;
}
.cdp_i:last-child, .cdp_i:nth-child(2), .cdp_i:nth-last-child(2) {
  display: inline-block;
}
.cdp_i.clickable {
  border: 2px solid white;
  border-radius: 10px;
  font-size: unset;
  padding: 12px;
}
.cdp_i.clickable img {
  width: 30px;
  height: 30px;
}
.cdp_i:hover {
  color: var(--item-selected-color);
  cursor: pointer;
}
.cdp:not([data-actpage="1"]) .cdp_i:nth-child(1) {
  display: inline-block;
}

.cdp[data-actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(0):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(2), .cdp[data-actpage="1"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(2) + .cdp_i:last-child, .cdp[data-actpage="1"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(3):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(3), .cdp[data-actpage="2"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(3) + .cdp_i:last-child, .cdp[data-actpage="2"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(4), .cdp[data-actpage="3"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(4) + .cdp_i:last-child, .cdp[data-actpage="3"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(5), .cdp[data-actpage="4"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(5) + .cdp_i:last-child, .cdp[data-actpage="4"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(6), .cdp[data-actpage="5"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(6) + .cdp_i:last-child, .cdp[data-actpage="5"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(7), .cdp[data-actpage="6"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(7) + .cdp_i:last-child, .cdp[data-actpage="6"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(8), .cdp[data-actpage="7"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(8) + .cdp_i:last-child, .cdp[data-actpage="7"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(9), .cdp[data-actpage="8"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(9) + .cdp_i:last-child, .cdp[data-actpage="8"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(10), .cdp[data-actpage="9"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(10) + .cdp_i:last-child, .cdp[data-actpage="9"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(11), .cdp[data-actpage="10"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(11) + .cdp_i:last-child, .cdp[data-actpage="10"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(12), .cdp[data-actpage="11"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(12) + .cdp_i:last-child, .cdp[data-actpage="11"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(13), .cdp[data-actpage="12"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(13) + .cdp_i:last-child, .cdp[data-actpage="12"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(14), .cdp[data-actpage="13"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(14) + .cdp_i:last-child, .cdp[data-actpage="13"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(15), .cdp[data-actpage="14"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(15) + .cdp_i:last-child, .cdp[data-actpage="14"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(16), .cdp[data-actpage="15"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(16) + .cdp_i:last-child, .cdp[data-actpage="15"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(17), .cdp[data-actpage="16"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(17) + .cdp_i:last-child, .cdp[data-actpage="16"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(18), .cdp[data-actpage="17"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(18) + .cdp_i:last-child, .cdp[data-actpage="17"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(19), .cdp[data-actpage="18"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(19) + .cdp_i:last-child, .cdp[data-actpage="18"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(20), .cdp[data-actpage="19"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(20) + .cdp_i:last-child, .cdp[data-actpage="19"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(21), .cdp[data-actpage="20"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(21) + .cdp_i:last-child, .cdp[data-actpage="20"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(22), .cdp[data-actpage="21"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(22) + .cdp_i:last-child, .cdp[data-actpage="21"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(23), .cdp[data-actpage="22"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(23) + .cdp_i:last-child, .cdp[data-actpage="22"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(24), .cdp[data-actpage="23"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(24) + .cdp_i:last-child, .cdp[data-actpage="23"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(25), .cdp[data-actpage="24"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(25) + .cdp_i:last-child, .cdp[data-actpage="24"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(26), .cdp[data-actpage="25"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(26) + .cdp_i:last-child, .cdp[data-actpage="25"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(27), .cdp[data-actpage="26"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(27) + .cdp_i:last-child, .cdp[data-actpage="26"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(28), .cdp[data-actpage="27"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(28) + .cdp_i:last-child, .cdp[data-actpage="27"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(29), .cdp[data-actpage="28"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(29) + .cdp_i:last-child, .cdp[data-actpage="28"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(30), .cdp[data-actpage="29"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(30) + .cdp_i:last-child, .cdp[data-actpage="29"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(31), .cdp[data-actpage="30"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(31) + .cdp_i:last-child, .cdp[data-actpage="30"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(32), .cdp[data-actpage="31"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(32) + .cdp_i:last-child, .cdp[data-actpage="31"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(33), .cdp[data-actpage="32"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(33) + .cdp_i:last-child, .cdp[data-actpage="32"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(34), .cdp[data-actpage="33"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(34) + .cdp_i:last-child, .cdp[data-actpage="33"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(35), .cdp[data-actpage="34"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(35) + .cdp_i:last-child, .cdp[data-actpage="34"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(36), .cdp[data-actpage="35"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(36) + .cdp_i:last-child, .cdp[data-actpage="35"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(37), .cdp[data-actpage="36"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(37) + .cdp_i:last-child, .cdp[data-actpage="36"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(38), .cdp[data-actpage="37"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(38) + .cdp_i:last-child, .cdp[data-actpage="37"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(39), .cdp[data-actpage="38"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(39) + .cdp_i:last-child, .cdp[data-actpage="38"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(40), .cdp[data-actpage="39"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(40) + .cdp_i:last-child, .cdp[data-actpage="39"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(41), .cdp[data-actpage="40"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(41) + .cdp_i:last-child, .cdp[data-actpage="40"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(42), .cdp[data-actpage="41"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(42) + .cdp_i:last-child, .cdp[data-actpage="41"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(43), .cdp[data-actpage="42"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(43) + .cdp_i:last-child, .cdp[data-actpage="42"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(44), .cdp[data-actpage="43"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(44) + .cdp_i:last-child, .cdp[data-actpage="43"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(45), .cdp[data-actpage="44"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(45) + .cdp_i:last-child, .cdp[data-actpage="44"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(46), .cdp[data-actpage="45"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(46) + .cdp_i:last-child, .cdp[data-actpage="45"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(47), .cdp[data-actpage="46"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(47) + .cdp_i:last-child, .cdp[data-actpage="46"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(48), .cdp[data-actpage="47"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(48) + .cdp_i:last-child, .cdp[data-actpage="47"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(49), .cdp[data-actpage="48"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(49) + .cdp_i:last-child, .cdp[data-actpage="48"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(50), .cdp[data-actpage="49"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(50) + .cdp_i:last-child, .cdp[data-actpage="49"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(51), .cdp[data-actpage="50"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(51) + .cdp_i:last-child, .cdp[data-actpage="50"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(52), .cdp[data-actpage="51"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(52) + .cdp_i:last-child, .cdp[data-actpage="51"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(53), .cdp[data-actpage="52"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(53) + .cdp_i:last-child, .cdp[data-actpage="52"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(54), .cdp[data-actpage="53"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(54) + .cdp_i:last-child, .cdp[data-actpage="53"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(55), .cdp[data-actpage="54"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(55) + .cdp_i:last-child, .cdp[data-actpage="54"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(56), .cdp[data-actpage="55"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(56) + .cdp_i:last-child, .cdp[data-actpage="55"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(57), .cdp[data-actpage="56"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(57) + .cdp_i:last-child, .cdp[data-actpage="56"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(58), .cdp[data-actpage="57"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(58) + .cdp_i:last-child, .cdp[data-actpage="57"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(59), .cdp[data-actpage="58"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(59) + .cdp_i:last-child, .cdp[data-actpage="58"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(60), .cdp[data-actpage="59"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(60) + .cdp_i:last-child, .cdp[data-actpage="59"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(61), .cdp[data-actpage="60"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(61) + .cdp_i:last-child, .cdp[data-actpage="60"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(62), .cdp[data-actpage="61"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(62) + .cdp_i:last-child, .cdp[data-actpage="61"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(63), .cdp[data-actpage="62"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(63) + .cdp_i:last-child, .cdp[data-actpage="62"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(64), .cdp[data-actpage="63"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(64) + .cdp_i:last-child, .cdp[data-actpage="63"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(65), .cdp[data-actpage="64"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(65) + .cdp_i:last-child, .cdp[data-actpage="64"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(66), .cdp[data-actpage="65"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(66) + .cdp_i:last-child, .cdp[data-actpage="65"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(67), .cdp[data-actpage="66"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(67) + .cdp_i:last-child, .cdp[data-actpage="66"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(68), .cdp[data-actpage="67"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(68) + .cdp_i:last-child, .cdp[data-actpage="67"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(69), .cdp[data-actpage="68"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(69) + .cdp_i:last-child, .cdp[data-actpage="68"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(70), .cdp[data-actpage="69"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(70) + .cdp_i:last-child, .cdp[data-actpage="69"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(71), .cdp[data-actpage="70"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(71) + .cdp_i:last-child, .cdp[data-actpage="70"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(72), .cdp[data-actpage="71"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(72) + .cdp_i:last-child, .cdp[data-actpage="71"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(73), .cdp[data-actpage="72"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(73) + .cdp_i:last-child, .cdp[data-actpage="72"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(74), .cdp[data-actpage="73"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(74) + .cdp_i:last-child, .cdp[data-actpage="73"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(75), .cdp[data-actpage="74"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(75) + .cdp_i:last-child, .cdp[data-actpage="74"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(76), .cdp[data-actpage="75"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(76) + .cdp_i:last-child, .cdp[data-actpage="75"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(77), .cdp[data-actpage="76"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(77) + .cdp_i:last-child, .cdp[data-actpage="76"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(78), .cdp[data-actpage="77"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(78) + .cdp_i:last-child, .cdp[data-actpage="77"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(79), .cdp[data-actpage="78"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(79) + .cdp_i:last-child, .cdp[data-actpage="78"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(80), .cdp[data-actpage="79"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(80) + .cdp_i:last-child, .cdp[data-actpage="79"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

.cdp[data-actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(80):not(:first-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(81), .cdp[data-actpage="80"] .active {
  color: var(--item-selected-color);
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(81) + .cdp_i:last-child, .cdp[data-actpage="80"] .active + .cdp_i:last-child {
  display: none !important;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(83):not(:last-child) {
  display: inline-block;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px;
}
.cdp[data-actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)):after {
  content: "...";
  color: #fff;
  font-size: 32px;
  margin-left: -6px;
}

/*# sourceMappingURL=Pagination.css.map */
