.indicator {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.indicator .box {
  margin-right: 20px;
}

.box {
  width: 50px;
  height: 50px;
  border-radius: 3px;
}
.box.flame {
  display: grid;
  place-items: center;
  background: #F92B53;
}
.box.stop {
  background: #FFCC33;
}
.box.water {
  background: #437DF2;
}
.box.player-dot {
  position: relative;
  height: 25px;
  width: 25px;
  margin: 12.5px 32.5px 20px 12.5px;
  border-radius: 50%;
  background: linear-gradient(135deg, #40DDFF 0%, #14BAE3 19.24%, #13B1E6 68.64%, #11AADF 81.77%, #0B98C5 100%);
}
.box.player-dot:before, .box.player-dot:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.box.player-dot:after {
  transform: translate(-50%, -50%) scale(1.75);
  background: rgba(64, 221, 255, 0.75);
}
.box.player-dot:before {
  transform: translate(-50%, -50%) scale(2);
  background: rgba(64, 221, 255, 0.5);
  animation: blink 2s forwards infinite;
}

@keyframes blink {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(2);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(3);
  }
}

/*# sourceMappingURL=MapIndicators.css.map */
