* {
  text-transform: uppercase;
  box-sizing: border-box;
}

input, textarea, select {
  text-transform: none;
}

#root, .page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
}

.page main {
  position: relative;
  width: calc(100vw - 390px);
  padding: 50px;
}

.page main p, .page main h1, .page main h2, .page main h3 {
  color: white;
}

h1, h2, h3, p {
  margin: 0;
}

h1, h2, h3 {
  font-weight: var(--h-weight);
}

h1 {
  font-size: var(--h1-size);
}

h2 {
  font-size: var(--h2-size);
}

h3 {
  font-size: var(--h3-size);
}

p {
  font-size: var(--p-size);
  font-weight: var(--p-weight);
}

p.p1 {
  font-size: var(--p1-size);
}

h1 a, h2 a, h3 a {
  color: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

.form-input {
  width: 100%;
  padding: 16px 8px;
  color: white;
  font-size: var(--p1-size);
  font-weight: var(--p-weight);
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
}

.form-input:not(.password):focus, .open {
  color: black;
  background: #FFFFFF;
  border-color: var(--item-selected-color);
  box-shadow: 0 0 4px var(--item-selected-color);
  outline: none;
}

.form-input:focus::placeholder {
  color: black;
}

.form-input:disabled, .form-input.disabled {
  color: #D9D9D9;
  border-color: #D9D9D9;
}

.form-input::placeholder {
  color: white;
}

.column {
  display: flex;
  flex-direction: column;
}

.icon:hover {
  cursor: pointer;
}

.dn {
  display: none;
}

header h1, header h2 {
  color: white;
}

header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2em);
  -webkit-backdrop-filter: blur(2em);
  z-index: 100;
}

.shadow h2 {
  text-align: center;
}

.form-component {
  min-width: 336px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #40DDFF;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.1);
}

.view-buttons {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  gap: 20px;
}

.gameplay {
  height: calc(100% - 80px);
  display: grid;
  align-items: flex-end;
  justify-content: center;
}

.dot {
  min-width: 16px;
  min-height: 16px;
  background-color: #66EEA2;
  border-radius: 50%;
}

.dot.red {
  background-color: red;
}

.connection-ip {
  display: grid;
  grid-template-columns: 1fr 16px;
}

.output {
  min-width: 60px;
  min-height: 60px;
  padding: 0 !important;
  display: grid;
  place-items: center;
  cursor: auto !important;
}

.output:hover {
  background: none !important;
  box-shadow: none !important;
  border: 2px solid white !important;
}

.back-button {
  min-width: 60px;
  min-height: 60px;
  display: grid;
  place-items: center;
}

.filter {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter input {
  width: 330px;
}

.page .MuiSlider-thumbColorPrimary {
  background: #13B4E4;
  box-shadow: none;
}

.page .MuiSlider-thumbColorPrimary:hover {
  box-shadow: var(--glowing-shadow);
}

.page .MuiSlider-markLabel {
  color: white;
  font-size: var(--p-size);
}

.page .MuiSlider-track {
  background: var(--item-selected-color);
}

.page .MuiSlider-rail {
  background: rgba(255, 255, 255, 0.2);
}

.page .MuiSlider-mark {
  height: 30px;
  background: white;
}

.page .MuiSlider-mark[data-index="0"], .page .MuiSlider-mark[data-index="2"] {
  display: none;
}

.page .MuiSlider-valueLabel {
  padding-bottom: 0;
  background: transparent;
  transform: translateY(-80%);
  font-size: var(--p-size);
  font-family: 'Ubuntu', 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
}

.temp {
  position: absolute;
  top: -100px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reverse {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  gap: 10px;
}

.jcc {
  justify-content: center;
}

.version {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}

.version span {
  text-transform: lowercase;
}
