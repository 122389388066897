.checkbox__container {
  width: min-content;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.checkbox__container:hover .checkbox {
  border-color: var(--item-selected-color);
}
.checkbox__container .checkbox {
  min-height: 30px;
  min-width: 30px;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}
.checkbox__container .checkbox:hover {
  border-color: var(--item-selected-color);
}

/*# sourceMappingURL=Checkbox.css.map */
