.end-screen .table {
  margin-top: 100px;
}
.end-screen h1, .end-screen .form-button {
  display: block;
  margin: 100px auto 0;
  text-align: center;
}
.end-screen .top .table__line {
  background: rgba(204, 204, 204, 0.5);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(26px);
  border-radius: 10px 10px 0 0;
}
.end-screen .bottom .table__line {
  background: var(--purple-gradient);
  backdrop-filter: blur(4px);
  border-radius: 0 0 10px 10px;
}

/*# sourceMappingURL=EndScreen.css.map */
