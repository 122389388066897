.selectContainer {
  position: relative;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 8px;
  border-radius: 10px;
  transition: border-radius 0.5s ease-in-out;
}
.select .control {
  padding: 18px;
  border-left: 2px solid white;
}
.select .control img {
  transition: transform 0.5s ease-in-out;
}
.select:hover, .select.is-active {
  background: white;
  color: black;
  border-color: var(--item-selected-color);
  box-shadow: var(--glowing-shadow);
  overflow: hidden;
  cursor: pointer;
}
.select:hover .control, .select.is-active .control {
  border-color: var(--item-selected-color);
  background: var(--button-gradient);
}
.select.is-active {
  border-bottom-left-radius: 0;
}
.select.is-active .control img {
  transform: rotateZ(180deg);
}

.options {
  position: absolute;
  width: 80%;
  color: black;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
  z-index: 2;
}
.options__option {
  padding: 18px 8px;
  border: 2px solid var(--item-selected-color);
  border-top: none;
  background: white;
  font-size: var(--p1-size);
  cursor: pointer;
}
.options__option:hover {
  background: var(--item-selected-color);
  color: white;
}
.options__option:last-of-type {
  border-radius: 0 0 10px 10px;
}
.options.hidden {
  transform: scaleY(0);
}

.selectContainer.small .control {
  padding: 16px;
}
.selectContainer.small .selected-option {
  padding: 16px 16px 16px 8px;
}
.selectContainer.small .options {
  bottom: 59px;
  width: 52%;
  transform-origin: bottom;
}
.selectContainer.small .options__option {
  border-top: 2px solid var(--item-selected-color);
  border-bottom: 0;
  text-align: center;
}
.selectContainer.small .options__option:first-of-type {
  border-radius: 10px 10px 0 0;
}
.selectContainer.small .options__option:last-of-type {
  border-radius: 0;
}
.selectContainer.small .select.is-active {
  border-top-left-radius: unset;
  border-bottom-left-radius: 10px;
}

/*# sourceMappingURL=Select.css.map */
