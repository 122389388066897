.nameblock {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: auto 0 36px;
}
.nameblock .initials {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C4C4C4;
  border-radius: 50%;
  border: 4px solid #FFCC33;
  font-size: 20px;
}
.nameblock .fullname {
  display: none;
  font-size: 18px;
  color: white;
}
.nameblock.full .initials {
  margin-right: 10px;
}
.nameblock.full .fullname {
  display: block;
}

/*# sourceMappingURL=NameBlock.css.map */
